import React from "react"
import Helmet from 'react-helmet'
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import dateFormat from 'dateformat'

export default ({ location, data }) => {
  const post = data.markdownRemark
  const startDateTime = parseDateString(post.frontmatter.startDateTime)
  const endDateTime = parseDateString(post.frontmatter.endDateTime)

  const selectedDate = (typeof location.state === 'undefined' || !location.state || !location.state.selectedDate)?
    null: location.state.selectedDate
  const selectedSessionType = (typeof location.state === 'undefined' || !location.state || !location.state.selectedSessionType) ?
    null : location.state.selectedSessionType

  function formatTime(date, format = 'h:MMtt') {
    return dateFormat(date, format).replace('am', ' a.m.').replace('pm', ' p.m.')
  }

  function parseDateString(date) {
    let p = date.split(/\D/)
    return new Date(p[0], p[1]-1, p[2], p[3], p[4], '00')
  }

  return (
    <Layout>
      <Helmet>
        <title>2022 CHCSD Schedule</title>
        <meta name="description" content="2022 CHCSD Schedule Page" />
      </Helmet>
    <div id="main" className="alt event">
      <section id="one">
        <div className="inner">
          <header>
            <p>
              <Link
                to={`/schedule`}
                className='button special small'
                state={{
                  'selectedDate': selectedDate,
                  'selectedSessionType': selectedSessionType
                }}>
                &laquo; Return to Schedule
              </Link>
            </p>
            <h1>{post.frontmatter.title}</h1>
            <h2>{post.frontmatter.speaker}</h2>
              <h2>{dateFormat(startDateTime,'dddd, mmmm d')} @ {formatTime(startDateTime)} &ndash; {formatTime(endDateTime)} <strong>{post.frontmatter.room.replace(/0([\d]+)/g,'$1')}</strong></h2>
          </header>
          <div className="content" dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </section>
    </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: {
        slug: {
          eq: $slug
        }
      }
    ) {
      html
      frontmatter {
        title
        startDateTime
        endDateTime
        room
        speaker
        sessionType
      }
    }
  }
`